<template>
    <div class="record-audio-listen">
        <div class="audio-wrapper">
            <el-button class="play-item play-item-vue" :class="playIcon" @click="handleAudioPlayStatus" :loading="Loading"></el-button>
            <div class="audio-time">
                <span class="time-item">{{ currentTime | formatTimeYeJi }}</span>
                /
                <span class="time-item">{{ timeCount | formatTimeYeJi }}</span>
            </div>
            <div class="control-wrap">
                <div class="progress-control"
                    @click="_handleBarClick"
                    @mousedown="_handleBarMouseDown"
                    @mouseup="_handleBarMouseUp"
                    @mousemove="_handleBarMouseOver"
                    @mouseleave="_handleBarMouseUp"
                >
                </div>
                <div class="control-btn" v-bind:style="{'left': progressLeft - controlBtnWidth / 2 + 'px'}"></div>
            </div>
            <div class="race-wrap" v-if="playing">
                 <el-dropdown
                    @command="handleCommand"
                    placement="top">
                    <span class="el-dropdown-link">
                        {{speedText}}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1.0">1.0X</el-dropdown-item>
                        <el-dropdown-item command="1.25">1.25X</el-dropdown-item>
                        <el-dropdown-item command="1.5">1.50X</el-dropdown-item>
                        <el-dropdown-item command="2.0">2.0X</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    import {Howl, Howler} from 'howler';

    function getElClientLeft(el) {
        let left = el.offsetLeft;
        let parent = el;
        while(parent = parent.offsetParent) {
            left += parent.offsetLeft;
        }
        return left;
    }

    export default {
        data() {
            return {
                width: 80,
                currentTimeCountDown: null,
                currentTime: 0,
                playing: false,
                progressLeft: 0,
                mouseDown: false,
                currentComponent: null,
                componentList: null,
                Loading:false,
                sound: null,

                speed: 1.0, // 速度
                currentSound: null, // 当前播放Id
                speedText: '倍数', 
            }
        },

        computed: {
            timeCount() {
                let count = 0;
                if(this.duration) {
                    count = parseFloat(this.duration);
                }
                return count * 60;
            },
            playIcon(){
                let playing = this.playing, Loading = this.Loading;
                if(playing && !Loading){
                    return 'play-btn'
                }
                if(!playing && !Loading){
                    return 'pause-btn'
                }
            }
        },
        props: {
            audioUrl: String,
            duration: [String, Number],
            progressWidth: {
                type: Number,
                default: 80
            },
            controlBtnWidth: {
                type: Number,
                default: 12
            }
        },
        mounted(){
            this.spinner = this.$refs.spinner;
            this.Loading = false;

            this.sound = new Howl({
                src: [this.audioUrl, this.audioUrl, this.audioUrl, this.audioUrl],
                format: ['mp3', 'wav', 'aac', 'webm']
            });
            this.sound.on('end', () => {
                this.playing = false;
            });
        },
        methods: {
            handleAudioPlayStatus() {
                if(!this.currentComponent || !this.componentList) {
                    this.currentComponent = this;
                    this.componentList = this.$parent.$children;
                }

                if(this.playing === true) {
                    this.playing = false;
                    this.handlePause();
                }else {
                    if(this.sound.state() == 'loaded') {
                        this.handleUnset();
                        this.playing = true;
                        this.handlePlay();
                    }else {
                        this.loading = true;
                        this.sound.once('load', () => {
                            this.loading = false;
                            this.handleUnset();
                            this.playing = true;
                            this.handlePlay();
                        });
                    }
                }
            },
            handlePlay() {
                if(this.currentTime == this.timeCount) {
                    this.currentTime = 0;
                    this.timeToProgress(0);
                }
                setTimeout(()=>{
                    this.currentSound = this.sound.play();
                    this.currentTimeCountDown = setInterval(()=>{
                        let time = Number(this.currentTime) + 1*this.speed;
                        this.currentTime = time;
                        this.timeToProgress(time);

                        if(time >= this.timeCount){
                            clearInterval(this.currentTimeCountDown);
                            this.currentTime = this.timeCount;
                            this.playing = false;
                            this.sound.stop();
                            this.currentSound = null;
                        }
                    },1000)
                },0)
            },
            handlePause() {
                clearInterval(this.currentTimeCountDown);
                this.playing = false;
                this.sound && this.sound.pause();
                this.currentSound = null;
            },
            setProgressBar(current) {
                let rate = current / this.progressWidth;
                this.currentTime = (this.timeCount * rate).toFixed(2);
                this.setCurrentTime(rate);
            },
            setCurrentTime(rate) {
                this.sound.seek(this.timeCount * rate);
            },
            timeToProgress(time) {
                let rate = time / this.timeCount;
                rate = rate >= 1 ? 1 : rate;
                this.progressLeft = rate * this.progressWidth;
            },
            _handleBarClick(ev) {
                if(this.sound.state() !== 'loaded') {
                    shortTips('音频加载中，请稍后');
                    return;
                }
                let target = ev.target;
                let left = ev.pageX - getElClientLeft(target);
                this.progressLeft = left;
                this.setProgressBar(left);
            },
            _handleBarMouseUp() {
                if(this.sound.state() !== 'loaded') {
                    return;
                }
                this.mouseDown = false;
            },
            _handleBarMouseDown(ev) {
                if(this.sound.state() !== 'loaded') {
                    return;
                }
                this.mouseDown = true;
            },
            _handleBarMouseOver(ev) {
                if(this.sound.state() !== 'loaded') {
                    return;
                }
                if(this.mouseDown) {
                    let target = ev.target;
                    let left = ev.pageX - getElClientLeft(target)
                    left = left < 0 ? 0 : left;
                    this.progressLeft = left;
                    this.setProgressBar(left >= (this.progressWidth - 10) ? this.progressWidth : left);
                }
            },
            handleUnset() {
                //todo: 使用emitter.js mixin发布消息，实现单一播放控制
                let that = this;
                this.componentList.forEach(item =>{
                    item.handlePause && item.handlePause();
                })
            },
            // 控制倍数
            handleCommand(command) {
                this.speedText = command == '1.0' ? '倍数' : command+'X';
                this.speed = parseFloat(command);
                this.sound.rate(this.speed, this.currentSound);
            },
        }
    }
</script>

<style lang="scss">
.record-audio-listen {
    background-color: #CBEEE6;
    width: 255px;
    height: 33px;
    border-radius: 16px;

    .audio-wrapper{
        width: 100%;
        height: 100%;
        line-height: 33px;
        position: relative;

        .play-item {
            width: 17px;
            height: 17px;
            line-height: 17px;
            display: inline-block;
            margin: 8px 0 8px 10px;
            cursor: pointer;
            vertical-align: top;
        }
        .play-btn {
            background: url('~@src/assets/images/icon/audio-pause.png') no-repeat left center;
        }
        .pause-btn {
            background: url('~@src/assets/images/icon/audio-play.png') no-repeat left center;
        }

        .audio-time {
            width: 90px;
            height: 33px;
            display: inline-block;
            color: #199b7e;
            vertical-align: top;

            .time-item {
                font-size: 14px;
                color: #199B7E;
                letter-spacing: 0;
            }
        }

        .control-wrap {
            position: relative;
            display: inline-block;
            width: 80px;
            height: 33px;

            .progress-control {
                position: absolute;
                width: 80px;
                height: 4px;
                background: rgba(25,155,126,.59);
                border-radius: 3.5px;
                cursor: pointer;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                z-index: 100;
            }

            .control-btn {
                width: 12px;
                height: 12px;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #199B7E;
                border-radius: 30px;
                cursor: pointer;
                z-index: 50;
            }
        }
        .race-wrap{
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            .el-dropdown-link{
                color: #199B7E;
            }
        }
    }
}
.record-audio-listen .audio-wrapper .play-item.play-item-vue{
    border: none;
    padding: 0;
    min-width: 0;
    &:before{
        background-color: #CBEEE6;
    }
    .el-icon-loading{
        color: #199B7E;
    }
}
</style>
