var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "record-audio-listen" }, [
    _c(
      "div",
      { staticClass: "audio-wrapper" },
      [
        _c("el-button", {
          staticClass: "play-item play-item-vue",
          class: _vm.playIcon,
          attrs: { loading: _vm.Loading },
          on: { click: _vm.handleAudioPlayStatus },
        }),
        _c("div", { staticClass: "audio-time" }, [
          _c("span", { staticClass: "time-item" }, [
            _vm._v(_vm._s(_vm._f("formatTimeYeJi")(_vm.currentTime))),
          ]),
          _vm._v("\n            /\n            "),
          _c("span", { staticClass: "time-item" }, [
            _vm._v(_vm._s(_vm._f("formatTimeYeJi")(_vm.timeCount))),
          ]),
        ]),
        _c("div", { staticClass: "control-wrap" }, [
          _c("div", {
            staticClass: "progress-control",
            on: {
              click: _vm._handleBarClick,
              mousedown: _vm._handleBarMouseDown,
              mouseup: _vm._handleBarMouseUp,
              mousemove: _vm._handleBarMouseOver,
              mouseleave: _vm._handleBarMouseUp,
            },
          }),
          _c("div", {
            staticClass: "control-btn",
            style: { left: _vm.progressLeft - _vm.controlBtnWidth / 2 + "px" },
          }),
        ]),
        _vm.playing
          ? _c(
              "div",
              { staticClass: "race-wrap" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { placement: "top" },
                    on: { command: _vm.handleCommand },
                  },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.speedText) +
                          "\n                "
                      ),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", { attrs: { command: "1.0" } }, [
                          _vm._v("1.0X"),
                        ]),
                        _c("el-dropdown-item", { attrs: { command: "1.25" } }, [
                          _vm._v("1.25X"),
                        ]),
                        _c("el-dropdown-item", { attrs: { command: "1.5" } }, [
                          _vm._v("1.50X"),
                        ]),
                        _c("el-dropdown-item", { attrs: { command: "2.0" } }, [
                          _vm._v("2.0X"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }